import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context for the position
const PositionContext = createContext();

/*
position- position of currently selected pin within the feature search system
userPosition- position of user
activeIcon - controls which icon of the left bar is active, used to ensure only one open at a time
pinlistopen- specifies that pin list is open to allow for adjusting of other ui elements
currentUserRoles- stores user role to allow for admin view
userID- store user id info

*/
// Create a provider component
export const PositionProvider = ({ children }) => {
  const [position, setPosition] = useState({ latitude: null, longitude: null, zoom: null, title:null });
  const [ dbPinData, setdbPinData] = useState()
  const [userPosition, setUserPosition] = useState({ latitude: null, longitude: null, zoom: null, elevation:null });
  const [ activeIcon, setActiveIcon] = useState({newPin:false, search:false, getInTouch:false,})
  const [pinListOpen, setPinListOpen]= useState(false)
  const [currentUserRoles, setCurrentUserRoles] = useState([])
  const [ userID, setUserID] = useState()
  const [installPrompt, setInstallPrompt] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [wbDownloaded, setwbDownloaded] = useState(false)
  const [spearheadDownloaded, setSpearheadDownloaded] = useState(false)

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault(); // Prevent the default prompt
      console.log("PWA install prompt detected.");
      setInstallPrompt(e); // Store the event so it can be triggered later
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  return (
    <PositionContext.Provider value={{
      position, setPosition,
      userPosition, setUserPosition,
      activeIcon, setActiveIcon,
      pinListOpen, setPinListOpen,
      currentUserRoles, setCurrentUserRoles,
      userID,  setUserID,
      dbPinData, setdbPinData,
      installPrompt, setInstallPrompt,
      progress, setProgress,
      isDownloading, setIsDownloading,
      wbDownloaded, setwbDownloaded,
      spearheadDownloaded, setSpearheadDownloaded,
      }}>
      {children}
    </PositionContext.Provider>
  );
};

// Custom hook to use the position context
export const usePosition = () => {
  return useContext(PositionContext);
};
