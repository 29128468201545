import React, { useEffect, useState,  } from "react";
import { IOSView, AndroidView, isIOS, isAndroid } from "react-device-detect";
import PWAPrompt from 'react-ios-pwa-prompt'
import {ListItem, List, ListItemButton, ListItemDecorator, Typography} from '@mui/joy'

import { usePosition } from "../MapUtils/MapContext/PositionContext"

import install from '../../assets/install.png'
const AddToHomeScreenButton = () => {
    const {installPrompt} = usePosition();
    const [showIOSPWA, setShowIOSPWA] = useState(false)

    const handleAndroidInstallClick = async () => {
        if (installPrompt) {
          installPrompt.prompt(); // Show the native PWA install prompt
        }
      };
    
      const isIOSStandalone = window.navigator.standalone;
    
      const onIOSClick = ()=>{
        isIOS 
          ? setShowIOSPWA(true)
          :console.log('not ios')
        isAndroid
          ?  console.log('isAndroid')
          :console.log('not android')
       } 
    

    return (
        <List>
<IOSView>
  {!isIOSStandalone &&
  <>
<ListItem  sx={{marginTop:'25px'}}>
<ListItemButton onClick={onIOSClick}><ListItemDecorator ><img className='navbarDecorator' src={install}/></ListItemDecorator>
<Typography fontSize={17} fontWeight='bold'>Install to Homescreen</Typography></ListItemButton>
</ListItem>  

    <PWAPrompt 
    isShown={showIOSPWA}    
    promptOnVisit={1}
    timesToShow={3}
    copyClosePrompt="Close"
    permanentlyHideOnDismiss={false} />



  </>
  }
</IOSView>
<AndroidView>
  {installPrompt &&
    <ListItem  sx={{marginTop:'25px'}}>
<ListItemButton onClick={handleAndroidInstallClick}><ListItemDecorator ><img className='navbarDecorator' src={install}/></ListItemDecorator>
<Typography fontSize={17} fontWeight='bold'>Install to Homescreen</Typography></ListItemButton>
</ListItem>  
    }
</AndroidView>
</List>

    )
}
export{ AddToHomeScreenButton}


