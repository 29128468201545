
const version = '1.2.4'
const preVersion = 'Beta'

/*
1.20
fixed notice modal for ipad
    - waiver
    - spearhead NOT NECESSARY
fixed login screen
potentially fixed offline login screen

1.2.1
hotfix for login logic
- fixed duplicate splashscreen display
- fixed login infinite loop
- fixed login kicking back to unauthenticated lgoin

1.2.2
- change name from feature search to Terrain Atlas
- adding features for offline mode


1.2.3
- adjustments to pin database entry

1.2.4

*/
export {version, preVersion}