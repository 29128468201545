import React, { useEffect, useState } from 'react'

import {Button} from '@mui/joy'
// functions for pin creation,fetching and updating

import refresh from '../../assets/refresh.png'
import { getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { createEntry, updateEntry, deleteEntry } from '../../graphql/mutations';
import { listEntries } from '../../graphql/queries';
import { usePinListPosition } from '../MapUtils/MapContext/PinListContext';



const client = generateClient();

// new pin creation
const createNewPin = async (coordinatesInput, titleInput, descriptionInput, userID) => {
  try {
    const user = await getCurrentUser()
    //console.log(user)
    const userHash= user.username

    //console.log(`Creating new pin coords ${coordinatesInput.lat} ${coordinatesInput.lng}, title ${titleInput}, desc ${descriptionInput}`);

    const QueryInput= {
      latitude:coordinatesInput.latitude.toString(),
      longitude:coordinatesInput.longitude.toString(),
      title: titleInput,
      description: descriptionInput,
      owner:userHash,
      creator:userID
    }
    //console.log(QueryInput)

    // Perform GraphQL mutation with required variables
    const { result } = await client.graphql({
      query: createEntry, // Ensure that createEntry is correctly imported or defined
      variables: {
        input: QueryInput
      },

    });

    console.log('pin created' );
  } catch (error) {
    console.error('Error creating pin:', error);
  }
};

//fetch pin list
const fetchPinList = async() => {
  try {
    console.log('fetching pins')
      const results = await client.graphql({ query: listEntries });
      return results  
  } catch (error) {
    console.error( error);
  }
}

//update pin
const updatePin =async(coordinatesInput, titleInput, descriptionInput, userID) => {

  try {
    const updatedEntry = await client.graphql({
      query: updateEntry,
      variables: {
          input: {
            latitude:coordinatesInput.latitude.toString(),
            longitude:coordinatesInput.longitude.toString(),
            title: titleInput,
            description: descriptionInput,
            owner:userID
          }
      }
    });
    console.log('update successful')
  } catch (error) {
    console.log('error updating pin', error)
  }
}

//delete pin
const deletePin = async(inputID) => {
  try {
    const deletedEntry = await client.graphql({
      query: deleteEntry,
      variables: {
          input: {
              id: inputID
          }
      }
    });
    console.log('deletion successful')
  } catch (error) {
    console.log('error deleting pin', error)
  }
}

const PinRefreshButton = (isButton) => {

  const [ loading, setLoading] = useState(false);

  const {setPinListPositions} = usePinListPosition()
  const handleFetchPinList= async() => {
          try {
            setLoading(true)
            const {data} = await fetchPinList()
            //console.log(data.listEntries.items)
            setPinListPositions(data.listEntries.items)
            setLoading(false)
            console.log('refresh done')
              
          } catch (error) {
              console.log('error fetching pins', error)
          }
      }

  useEffect(() => {
    handleFetchPinList()
  },[])
  
  return (
    <>
    {isButton &&
      <Button loading={loading} variant='outlined' color='neutral' onClick={()=>handleFetchPinList()} sx={{marginBottom:'30px', alignSelf:'center'}}>
        <img className='refreshButton' src={loading? '': refresh} alt='refresh'></img>
      </Button>
    }
    </>
  )
} 

export {createNewPin, updatePin, deletePin, fetchPinList, PinRefreshButton}
