import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { uploadData, list } from 'aws-amplify/storage';

import { FileUploader } from '../../utils/fileUpload';
import { usePosition } from '../../utils/MapUtils/MapContext/PositionContext';

import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';


export default function AdminAccess({handleSetCurrentMap}) { 
    const {currentUserRoles} = usePosition();
    const navigate = useNavigate()

// return to home
    const handleReturn = () => {
        handleSetCurrentMap('WBLowRes')
    }

// ensures admin only access
    useEffect(()=>{
        currentUserRoles?.includes('Admin')
        ? console.log('hello admin') : handleReturn()
    }, [])

// list all current maps
/*
    const listMaps  = async () => {    try {
      const result = await list({
        prefix: 'photos/'
      });
    } catch (error) {
      console.log(error);
    }
}*/
// upload new map
/*
    const uploadNewMap  = async () => {
        try {
            const result = await uploadData({
                key: filename,
                data: file
            }).result;
            console.log('Succeeded: ', result);
        } catch (error) {
            console.log('Error : ', error);
        }
    }
    */
    return (
        <Box width='100%' height='calc(100dvh - 60px)'>
            <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
                <Typography>This is Admin Access</Typography>
                <Typography>tbd</Typography>
                <FileUploader></FileUploader>
                <Button color='neutral' onClick={handleReturn}>Return to main page</Button>
            </Stack>
        </Box>
    )
}