import shlg from '../../assets/navigation/sh-lg.png'
import wblg from '../../assets/navigation/wb-lg.png'

const tileRangeSpearhead = {
  13: { xMin: 2000, xMax: 3000, yMin: 2500, yMax: 3500 },
  14: { xMin: 4000, xMax: 6000, yMin: 5000, yMax: 7000 },
  15: { xMin: 8000, xMax: 12000, yMin: 10000, yMax: 14000 },
  16: { xMin: 16000, xMax: 24000, yMin: 20000, yMax: 28000 },
  17: { xMin: 32000, xMax: 48000, yMin: 40000, yMax: 56000 }
};
  const tileRangeWB = {
  13: { xMin: 1296, xMax: 1300, yMin: 2773, yMax: 2777 },
  14: { xMin: 2593, xMax: 2600, yMin: 5547, yMax: 5554 },
  15: { xMin: 5187, xMax: 5201, yMin: 11095, yMax: 11108 },
  16: { xMin: 10374, xMax: 10402, yMin: 22191, yMax: 22216 },
  17: { xMin: 20749, xMax: 20805, yMin: 44382, yMax: 44433 },
  18: { xMin: 41499, xMax: 41611, yMin: 88764, yMax: 88867 }
};

const mapData = {
    wb256600: {
        mapName:'Whistler Blackcomb (Lo-Res)',
        map:'WBLowRes',
        version: '2025',
        lastUpdated: 'March 05,2025',
        projection: 'UTM Zone 10N',
        datum: 'NAD83',
        contourInterval: '10m',
        scale: '1:3,937',
        offlineAvailable:true,
        offlineComingSoon:false,
        downloadURL:'https://s3mapdatastoragebucket82d4a-dev.s3.us-east-1.amazonaws.com/mapData/wb/wb256v3/600/{z}/{x}/{y}.jpg',
        img:wblg,
        center:[50.063541599446594, -122.94765848949606],
        maxBounds:[[50.03372798633418, -123.00894714633222], [50.12334633850441, -122.85628672950945]],
        minZoom:13,
        maxZoom:18,
        tileLayerBaseUrl:'https://s3mapdatastoragebucket82d4a-dev.s3.us-east-1.amazonaws.com/mapData/wb/wb256v4/600/',
        tileRange:tileRangeWB,

    },
    wb512600: {
        mapName:'Whistler Blackcomb (High-Res)',
        map:'WBHighRes',
        version: '2025',
        lastUpdated: 'March 05,2025',
        projection: 'UTM Zone 10N',
        datum: 'NAD83',
        contourInterval: '10m',
        scale: '1:3,937',
        offlineAvailable:false,
        offlineComingSoon:false,
        downloadURL:'',
        img:wblg,
        center:[50.063541599446594, -122.94765848949606],
        maxBounds:[[50.03372798633418, -123.00894714633222], [50.12334633850441, -122.85628672950945]],
        minZoom:13,
        maxZoom:18,
        tileLayerBaseUrl:'https://s3mapdatastoragebucket82d4a-dev.s3.us-east-1.amazonaws.com/mapData/wb/wb512v4/600/',
        tileRange:tileRangeWB,
    },
    spearhead: {
        mapName:'Spearhead Traverse',
        map:'spearhead',
        version: '2.0',
        lastUpdated: '2020',
        projection: 'UTM Zone 10N',
        datum: 'NAD83',
        contourInterval: '20m',
        scale: '1:10,597',
        offlineAvailable:false,
        offlineComingSoon:true,
        downloadURL:'https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/spearhead/shtiles/{z}/{x}/{y}.jpg',
        img:shlg,
        center:[50.0504331639911, -122.87196525397562],
        maxBounds:[[49.98292691723076, -123.01133478052596], [50.10811971405545, -122.75551127830491]],
        minZoom:13,
        maxZoom:17,
        tileLayerBaseUrl:'https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/spearhead/shtiles/',
        tileRange:tileRangeSpearhead,
    }
};
export {mapData, tileRangeSpearhead, tileRangeWB}