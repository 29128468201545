import { Box, Button, Typography, Grid, Stack, Modal, } from '@mui/joy';
import React, { useState,  } from 'react';

import L from 'leaflet'
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet'
import {Tooltip as TooltipLeaflet} from 'react-leaflet';
import { isMobile } from 'react-device-detect';

import { BasicAssets } from '../assets/basicAssets';
import location from '../assets/location.png'
import loginBG from '../assets/loginBG.png'

import {FeatureSearch, FullSearchCard} from '../utils/userDataFetching/FeatureSearch';
import { ToggleZoomControl } from '../utils/MapUtils/MapFunctions/ToggleZoomControl';
import { usePosition } from '../utils/MapUtils/MapContext/PositionContext';
import { CenterOnPositionFunction, TogglePinList, GPSLocatorComponent } from '../utils/MapUtils/MapFunctions/BasicMapFunctions';
import {PinCreationToggle} from '../utils/userDataFetching/pinCreationComponent';
import {SupportToggle} from '../utils/Modals/SupportToggle';
//import { OfflineTileLayer, useTileCaching } from '../utils/offlineUtils/offlineUtilFunctions';
import { useTileCaching, OfflineTileLayer } from '../utils/offlineUtils/offlineUtilFunctions';
import { tileRangeSpearhead, tileRangeWB } from '../utils/localStorage/MapUtilStorage';
import { mapData } from '../utils/localStorage/MapUtilStorage';
//import '../App.css'
import 'leaflet/dist/leaflet.css';
import 'leaflet-gps/src/leaflet-gps.css';
import '../App.css'
import zIndex from '@mui/material/styles/zIndex';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png'
});

// custom icon for the location
const customIconRed = new L.Icon({
  iconUrl: BasicAssets.redLocation,
  iconSize: [30, 30 ], // size of the icon
  iconAnchor: [15, 30], // point of the icon which will correspond to marker's location
});
const customCyanLocation = new L.Icon({
  iconUrl: BasicAssets.cyanLocation,
  iconSize: [30, 30 ], // size of the icon
  iconAnchor: [15, 30], // point of the icon which will correspond to marker's location
});

// find location
const FinderComponent = ({ setLocation }) => {
  const map = useMapEvents({

      locationfound: (location) => {
          //console.log('location found:', location);
          setLocation(location.latlng);
      },
  });

  const handleLocate = () => {
    map.locate({ setView: true, maxZoom: 16 });
    setLocation(location.latlng);
};

function onLocationFound(e) {
  var radius = e.accuracy;

  L.marker(e.latlng).addTo(map)
      .bindPopup("You are within " + radius + " meters from this point").openPopup();

  L.circle(e.latlng, {
    color: 'grey',        // border color
    fillColor: 'none',    // fill color
    fillOpacity: 0,
    stroke: 1,     // fill opacity
    radius: radius
}).addTo(map);
}

map.on('locationfound', onLocationFound);

  
  return (
    
    <Button 
      variant="s" 
      color="neutral" 
      style={{ width:'45px', height:'40px', position: 'fixed', top: '0px', right: '8px', margin: '8px', zIndex:1000}}
      onClick={handleLocate}>
        
    </Button>
  )
}
const BlankMapBody = ({handleSetCurrentMap}) => {


  return (
    <>
    <Box sx={{
      backgroundImage:`url(${loginBG})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      filter: 'blur(2px)',
      position:'fixed',
      height:'calc(100dvh - 60px)',
      width:'100vw'}}/>
      <Box
        sx={{
          position:'fixed',
          height:'calc(100dvh - 60px)',
          width:'100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          }}>

        <Stack spacing={2} alignItems="center" py={2}
        sx={{
          backgroundColor:'rgba(255,255,255,0.70)',
          border: '2px solid lightgrey',
          borderRadius: '20px',
          }}>
          <Typography level='h2' >Map Selection</Typography>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={5} sx={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Button color='neutral' variant='soft' sx={{width:'150px', height:'100px', border: '1px solid lightgrey',}} onClick={()=>handleSetCurrentMap('WBLowRes')}>Whistler Blackcomb Map</Button>
            </Grid>
            <Grid item xs={5} sx={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Button color='neutral' variant='soft' sx={{width:'150px', height:'100px', border: '1px solid lightgrey',}} onClick={()=>handleSetCurrentMap('spearhead')}> Spearhead Map</Button>
            </Grid>
          </Grid>
        </Stack>

    </Box>
    </>
  )
}

const MapBody = ({ center, zoom, minZoom, maxZoom, maxBounds, tileLayerBaseUrl, isSignedIn, offline, tileRange  }) => {

  const [modalOpen, setModalOpen] = useState(false);
  const { position, setPosition, userPosition, setUserPosition,dbPinData } = usePosition();
  //const {userPosition, setUserPosition} = useUserPosition()
  const [disableZoom, setDisableZoom] = useState(false);
  const [fullSearchOpen, setFullSearchOpen] = useState(false)

  // Function to validate tile coordinates
  const getValidTileUrl = (z, x, y) => {
    if (tileRange && tileRange[z]) {
      const { xMin, xMax, yMin, yMax } = tileRange[z];

      // Clamp values to valid ranges
      const validX = Math.max(xMin, Math.min(xMax, x));
      const validY = Math.max(yMin, Math.min(yMax, y));
      console.log(`${tileLayerBaseUrl}${z}/${validX}/${validY}.jpg`)
      return `${tileLayerBaseUrl}${z}/${validX}/${validY}.jpg`;
    }
    return `${tileLayerBaseUrl}${z}/${x}/${y}.jpg`;
  };

  useTileCaching(maxBounds, minZoom, maxZoom, tileLayerBaseUrl, tileRange);

  const fullTileURL= `${tileLayerBaseUrl}{z}/{x}/{y}.jpg`

  const handleOutOfBounds = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleMouseEnter = () => {
    //console.log('mouse enter mini')
    setDisableZoom(true);
  };

  const handleMouseLeave = () => {
    //console.log('mouse leave mini')
    setDisableZoom(false);
  };

  const handleFullSearchOpen =() => {
    //console.log('hello')
    if (isMobile) {
      setFullSearchOpen(true)
    }
  }
  const handleFullSearchClose =() => {
    setFullSearchOpen(false)
  }

  return (
    <>

      <MapContainer
          id='map'
          center={center}
          zoom={zoom}
          zoomControl={false}
          minZoom={minZoom}
          maxZoom={maxZoom}
          //maxBounds={maxBounds}
          attributionControl={false}
          style={{
            height:'100vh',
            width:'100vw',
            backgroundColor:'black',
            
           }}
          zoomSnap={0}
      >
          {/*<FinderComponent setLocation={setLocation} />*/}
          {!offline && <TileLayer attribution='offline map' url={fullTileURL} zIndex={1}/> }
          {offline && <OfflineTileLayer attribution='offline map' url={getValidTileUrl('{z}', '{x}', '{y}')} zIndex={1}/> }
          {/*below is possible base layer*/}
          {/*<TileLayer attribution='offline map' url={'https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/512/450/{z}/{x}/{y}.jpg'} zIndex={0} opacity={0.5}/>*/}
          <Stack spacing={1} sx={{marginLeft:'10px', width:'30px'}}>

          <GPSLocatorComponent
            setCurrentPosition={setUserPosition}
            currentPosition={userPosition}
            maxBounds={maxBounds} 
            handleOutOfBounds={handleOutOfBounds}
            setDisableZoom={setDisableZoom}
            />
          <FeatureSearch centerFunction={setPosition} setDisableZoom={setDisableZoom} />
          <PinCreationToggle setDisableZoom={setDisableZoom} disableZoom={disableZoom} isSignedIn={isSignedIn}/>
          <SupportToggle setDisableZoom={setDisableZoom} disableZoom={disableZoom}/>
          {/*below is position of feature*/}
          {position.latitude && position.longitude && (
            <>
            <Marker  
              eventHandlers={{
                click: () => {
                  handleFullSearchOpen();
                },
              }}
              position={[position.latitude, position.longitude]} icon={customCyanLocation}>
            </Marker>
            {fullSearchOpen && (
      <FullSearchCard
        inputData={dbPinData}
        open={fullSearchOpen}
        handleClose={handleFullSearchClose}
        handleSearchLocator={()=>console.log('open')}
        setFullSearchDataDisplay={handleFullSearchClose}
        handleSearchBarOpen={()=>console.log('open')}
        setDisableZoom={setDisableZoom}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
    )}
            </>
          )}
          {/*below is position of user*/}
          {userPosition?.latitude && userPosition?.longitude && (
            <Marker position={[userPosition.latitude, userPosition.longitude]} icon={customIconRed} />
          )}
          {/*<CenterOnPositionComponent currentPosition={currentPosition} />*/}
          <CenterOnPositionFunction setDisableZoom={setDisableZoom}/>
          <TogglePinList setDisableZoom={setDisableZoom}/>
          <ToggleZoomControl disableZoom={disableZoom} />
          </Stack>
          
      </MapContainer>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: 4,
            margin: '20vw',
            boxShadow: 24,
            textAlign: 'center',
            zIndex:1005,
            
          }}
        >
          <Stack spacing={3}
          sx={{
            display:'flex',
            alignItems:'center'
          }}>
          <Stack spacing={1}>
            <Typography level="h4">Out of Bounds</Typography>
            <Typography>Your current location is outside of the selected map.</Typography>
          </Stack>
          <Button onClick={handleCloseModal}>Close</Button>
          </Stack>
        </Box>
      </Modal>
      </>
  );
};



const SpearheadMapBody = ({isSignedIn, offline}) => {

  return (
  <MapBody
      isSignedIn={isSignedIn}
      center={mapData.spearhead.center}
      zoom={13}
      minZoom={mapData.spearhead.minZoom}
      maxZoom={mapData.spearhead.maxZoom}
      maxBounds={mapData.spearhead.maxBounds}
      offline={offline}
      tileLayerBaseUrl={mapData.spearhead.tileLayerBaseUrl}
      tileRange={tileRangeSpearhead}
  />
)};

const WB256600 = ({isSignedIn, offline}) => {

  return (
  <MapBody
      isSignedIn={isSignedIn}
      center={mapData.wb256600.center}
      zoom={14}
      minZoom={mapData.wb256600.minZoom}
      maxZoom={mapData.wb256600.maxZoom}
      maxBounds={mapData.wb256600.maxBounds}
      offline={offline}
      tileLayerBaseUrl={mapData.wb256600.tileLayerBaseUrl}
      tileRange={tileRangeWB}
  />
)};

const WB512600 = ({isSignedIn, offline}) => {

return (
<MapBody
  isSignedIn={isSignedIn}
  center={mapData.wb512600.center}
  zoom={14}
  minZoom={mapData.wb512600.minZoom}
  maxZoom={mapData.wb512600.maxZoom}
  maxBounds={mapData.wb512600.maxBounds}
  offline={offline}
  tileLayerBaseUrl={mapData.wb512600.tileLayerBaseUrl}
  tileRange={tileRangeWB}
/>
)};
const WB512300 = ({isSignedIn, offline}) => {
  
  return (
  <MapBody
      isSignedIn={isSignedIn}
      center={[50.063541599446594, -122.94765848949606]}
      zoom={14}
      minZoom={13}
      maxZoom={18}
      maxBounds={[[50.03372798633418, -123.00894714633222], [50.12334633850441, -122.85628672950945]]}
      tileLayerBaseUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/512/300/'
      tileRange={tileRangeWB}
      />
    )};
    

const WB1024600 = ({isSignedIn, offline}) => {
  
  return (
  <MapBody

      isSignedIn={isSignedIn}
      center={mapData.wb512600.center}
      zoom={14}
      minZoom={mapData.wb512600.minZoom}
      maxZoom={mapData.wb512600.maxZoom}
      maxBounds={mapData.wb512600.maxBounds}
      offline={offline}
      tileLayerBaseUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/wb1024v4/600/'
      tileRange={tileRangeWB}
  />
)};

const WB1024450 = ({isSignedIn, offline}) => {

  return (
  <MapBody
      isSignedIn={isSignedIn}
      center={[50.063541599446594, -122.94765848949606]}
      zoom={14}
      minZoom={13}
      maxZoom={18}
      maxBounds={[[50.03372798633418, -123.00894714633222], [50.12334633850441, -122.85628672950945]]}
      offline={offline}
      tileLayerBaseUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/600/'
      tileRange={tileRangeWB}
  />
)};

export{ 
  SpearheadMapBody,
  BlankMapBody,
  WB512300,
  WB256600,
  WB512600,
  WB1024600,
  WB1024450,
};
