import { useState } from "react";
import { isMobile } from "react-device-detect";
import { openDB } from "idb";
import L from "leaflet";
import { TileLayer } from "react-leaflet";
import {Button, Stack, Typography, Modal, ModalClose, Box} from '@mui/joy'
import { usePosition } from "../MapUtils/MapContext/PositionContext";

const TILE_DB_NAME = "MapTilesDB";
const TILE_STORE_NAME = "tiles";

const initDB = async () => {
  return openDB(TILE_DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(TILE_STORE_NAME)) {
        db.createObjectStore(TILE_STORE_NAME);
      }
    },
  });
};

const storeTile = async (url, blob) => {
  const db = await initDB();
  const tx = db.transaction(TILE_STORE_NAME, "readwrite");
  await tx.objectStore(TILE_STORE_NAME).put(blob, url);
  await tx.done;
};

const getTile = async (url) => {
  const db = await initDB();
  console.log(`fetching stored tile${url}`)
  return db.get(TILE_STORE_NAME, url);
};

const useTileCaching = (maxBounds, minZoom, maxZoom, tileLayerBaseUrl, tileRange) => {

  const { progress, setProgress, isDownloading, setIsDownloading} = usePosition();

  const preloadTiles = async () => {
    setIsDownloading(true);
    let totalTiles = 0;
    let downloadedTiles = 0;

    // Calculate total tiles to download
    for (let z = minZoom; z <= maxZoom; z++) {
      const range = tileRange[z];
      if (!range) continue;
      totalTiles += (range.xMax - range.xMin + 1) * (range.yMax - range.yMin + 1);
    }

    for (let z = minZoom; z <= maxZoom; z++) {
      const range = tileRange[z];
      if (!range) continue;

      for (let x = range.xMin; x <= range.xMax; x++) {
        for (let y = range.yMin; y <= range.yMax; y++) {
          const tileUrl = `${tileLayerBaseUrl}${z}/${x}/${y}.jpg`;
          const cachedTile = await getTile(tileUrl);

          if (!cachedTile) {
            try {
              const response = await fetch(tileUrl);
              if (response.ok) {
                const blob = await response.blob();
                await storeTile(tileUrl, blob);
              }
            } catch (error) {
              console.error(`Failed to fetch tile: ${tileUrl}`, error);
            }
          }

          downloadedTiles++;
          setProgress(Math.round((downloadedTiles / totalTiles) * 100));
        }
      }
    }

    setIsDownloading(false);
  };

  return { preloadTiles };
};

const TileDownloadComponent = ({ maxBounds, minZoom, maxZoom, tileLayerBaseUrl, tileRange }) => {
  const { preloadTiles, } = useTileCaching(maxBounds, minZoom, maxZoom, tileLayerBaseUrl, tileRange);
  const { isDownloading, progress } = usePosition();
  const [openDownloadModal, setOpenDownloadModal] = useState(false)

  const handleDownloadModalOpen = () => {
    setOpenDownloadModal(true)
  }
  const handleDownloadModalClose = () => {
    setOpenDownloadModal(false)
  }
  const handleDownloadClick = () => {
    preloadTiles()
    setOpenDownloadModal(false)
  }
  return (
    <>
      <Button color="neutral" variant= 'outlined' onClick={handleDownloadModalOpen} disabled={isDownloading}>
        {isDownloading ? `Downloading...${progress}%` : "Download map for Offline Use"}
      </Button>
      <Modal open={openDownloadModal} onClose={handleDownloadModalClose}>
        <Box  sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: isMobile ? '95%' : '450px',
                            maxWidth: isMobile ? '450px' : '450px',
                            bgcolor: 'white',
                            boxShadow: 24,
                            p: isMobile?3:4,
                            borderRadius: '8px',
                            textAlign: 'center',
                        }}>
          <ModalClose/>
          <Stack spacing={1}>
            <Typography level='h3'>A Few Notes Before Download</Typography>
            <Typography>This is a work in progress, and please report any bugs you encounter so we can build a better experience!</Typography>
            <Typography> This is a large download, connect to a stable wifi for a faster download</Typography>
            <Typography>Download progress is tracked in the bottom right corner, and the download can be paused and resumed at anytime</Typography>
            <Button color="neutral" variant= 'outlined' onClick={handleDownloadClick}>Download Map</Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

const DownloadProgressBar = () => {
  const {isDownloading, progress } = usePosition(); 
  return (
      <>
      {isDownloading && (
        <>
          {/*<progress value={progress} max="100"></progress>*/}
          <Typography fontSize={12}>loading {progress}%</Typography>
        </>
      )}</>
  )
}


const OfflineTileLayer = ({ url, ...props }) => {
  // This function modifies the tile URL and checks cache before returning a URL
  const tileUrlFunction = ({ x, y, z }) => {
    const tileUrl = url.replace("{z}", z).replace("{x}", x).replace("{y}", y);

    return new Promise(async (resolve) => {
      const cachedTile = await getTile(tileUrl);

      if (cachedTile) {
        resolve(URL.createObjectURL(cachedTile));
      } else {
        try {
          console.log(tileUrl)
          const response = await fetch(tileUrl);
          if (response.ok) {
            const blob = await response.blob();
            await storeTile(tileUrl, blob);
            resolve(URL.createObjectURL(blob));
          } else {
            resolve(tileUrl); // Fallback if fetch fails
          }
        } catch (error) {
          console.error(`Tile fetch failed: ${tileUrl}`, error);
          resolve(tileUrl); // Fallback on network error
        }
      }
    });
  };

  return <TileLayer {...props} url={url} tileUrlFunction={tileUrlFunction} />;
};

const OfflineDownloadButton = () => {

  return (
    <Button>
      Download map for Offline Use
    </Button>
  )
}

export {OfflineTileLayer, useTileCaching, OfflineDownloadButton, TileDownloadComponent, DownloadProgressBar}